body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

i.fa.fa-angle-left {
  font-style: normal;
}
i.fa.fa-angle-right {
  font-style: normal;
}
i.fa.fa-angle-left:after {
  content: "<";
}
i.fa.fa-angle-right:after {
  content: ">";
}
.ant-alert{
  z-index: 9999;
}
.hrv-header-container {
    position: fixed;
    top: 0;
    left: 60px;
    right: 0;
    z-index: 511;
    transition: box-shadow .25s;
}

.hrv-header-container.active {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 2px 6px 2px rgba(60, 64, 67, 0.149);
}

.hrv-header {
    position: relative;
    display: flex;
    background: #FFF;
    padding: 0 40px;
}

.hrv-header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 auto;
    overflow: hidden;
}

.hrv-header-breadcrumb {
    margin: 16px 0 3px;
    overflow: hidden;
}

@media(max-width:767px) {
    .hrv-header-breadcrumb {
        display: none;
    }
}

.hrv-header-page-title {
    margin-top: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #212121;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hrv-header-menu {
    display: flex;
    align-items: center;
    padding: 0 24px;
}

.hrv-header-menu li {
    margin: 0 15px;
}

.hrv-header-menu li:first-child {
    margin-left: 0;
}

.hrv-header-menu li a {
    color: #FFF;
    font-size: 14px;
    display: block;
    position: relative;
    white-space: nowrap;
}

.hrv-header-menu li a:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background: transparent;
}

.hrv-header-menu li a.active {
    font-weight: 500;
}

.hrv-header-menu li a.active:after {
    background: #FFF;
}

.hrv-header-actions {
    flex: 0 0 auto;
    max-width: 100%;
}

.hrv-header-nofify {
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 16px 0;
}

.hrv-header-nofify .hrv-header-nofify-item {
    position: relative;
    line-height: 32px;
}

.hrv-header-nofify .hrv-header-nofify-item:last-child {
    margin-right: 0;
}

.hrv-header-nofify-item-count {
    position: absolute;
    top: -5px;
    right: -10px;
    font-size: 8px;
    text-align: center;
    background: #FF3B30;
    font-weight: bold;
    border-radius: 100%;
    min-width: 16px;
    min-height: 16px;
}

.hrv-header-profile {
    display: flex;
    align-items: center;
    padding: 0 0 0 20px;
    margin: 16px 0;
    border-left: 1px solid #E3E9ED;
    cursor: pointer;
}

.hrv-header-profile-dropdown {
    margin-left: 20px;
    max-width: 200px;
}

.hrv-header-profile-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.hrv-header-profile-content {
    margin-top: 2px;
    font-size: 12px;
    line-height: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.hrv-header-profile-arrow {
    color: #808080
}

@media(max-width:767px) {
    .hrv-header-profile-info {
        display: none;
    }
}

.hrv-header-logo-hamburger {
    margin-right: -10px;
    padding-right: 10px;
    cursor: pointer;
    color: rgba(33, 33, 33, 0.5);
    line-height: 24px;
}

.hrv-header-hamburger-popover-container {
    padding: 24px;
    max-width: 440px;
}

.hrv-header-hamburger-group+.hrv-header-hamburger-group {
    margin-top: 20px;
}

.hrv-header-hamburger-header {
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    color: #212121;
    padding: 0 0 16px 0;
}

.hrv-header-hamburger-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -16px;
}

.hrv-header-hamburger-item {
    margin-top: 16px;
    width: 78px;
}

.hrv-header-hamburger-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin: auto;
    background: #2979FF;
    border-radius: 100%;
}

.hrv-header-hamburger-icon svg {
    fill: #FFF;
}

.hrv-header-hamburger-name {
    font-size: 12px;
    line-height: 14px;
    color: #212121;
    text-align: center;
    margin: 5px 0;
}

.item-eform .hrv-header-hamburger-icon {
    background: #2979FF;
}

.item-mail .hrv-header-hamburger-icon {
    background: #22C993;
}

.item-booking .hrv-header-hamburger-icon {
    background: #FFCC00;
}

.item-ic .hrv-header-hamburger-icon {
    background: #FF8B00;
}

.item-notify .hrv-header-hamburger-icon {
    background: #FC625D;
}

.item-task .hrv-header-hamburger-icon {
    background: #FF8B00;
}

.item-hr .hrv-header-hamburger-icon {
    background: #FC625D;
}

.item-bms .hrv-header-hamburger-icon {
    background: #22C993;
}

.item-deployment .hrv-header-hamburger-icon {
    background: #2979FF;
}

.item-point .hrv-header-hamburger-icon {
    background: #FFCC00;
}

.item-libary .hrv-header-hamburger-icon {
    background: #22C993;
}

.item-news .hrv-header-hamburger-icon {
    background: #FF8B00;
}

.item-channel .hrv-header-hamburger-icon {
    background: #FFCC00;
}

.item-shorcut .hrv-header-hamburger-icon {
    background: #FC625D;
}

.item-tfticket .hrv-header-hamburger-icon {
    background: #FDBD41;
}

.item-banner .hrv-header-hamburger-icon {
    background: #2979FF;
}

.item-system .hrv-header-hamburger-icon {
    background: #FF8B00;
}

.item-shortcut-link .hrv-header-hamburger-icon {
    background: rgba(41, 121, 255, 0.1);
}

.item-shortcut-link-left .hrv-header-hamburger-icon>svg {
    position: relative;
    left: 2px;
}

.hrv-header-hamburger-item:hover .hrv-header-hamburger-name {
    color: #2979FF;
}

.hrv-header-btn-mobile {
    display: none;
}

.hrv-header-btn-mobile .hrv-header-btn-menu {
    background: transparent;
}

@media (max-width:767px) {
    .hrv-header {
        padding: 0 20px;
    }
    .hrv-header-hamburger-popover-container {
        padding: 20px;
        max-width: 370px;
    }
    .hrv-header-btn-mobile {
        display: block;
    }
    .hrv-header-container {
        left: 0;
    }
}

.hrv-header-profile-arrow svg {
    fill: grey;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}
.ui-main {
    padding-top: 64px;
    padding-left: 60px;
}

.hrv-menu-container:not(.sidebar-expanded-desktop):hover {
    width: 240px;
}

.hrv-menu {
    height: calc(100vh - 150px);
}

.hrv-menu-container:not(:hover) {

}

    .hrv-menu-container:not(:hover) .hrv-menu-item.list-menu-item {
        display: none;
    } 

    .hrv-menu-item-link {
        padding-right: 15px;
    }

    .hrv-menu-item-link .hrv-menu-item-icon svg {
        fill: #4D4D4D;
    }

    .hrv-menu-item-link.open .hrv-menu-item-icon svg {
        fill: #2979FF;
    }

.hrv-menu-logo {
    padding-top: 16px !important;
    padding-bottom: 34px !important;
}

.hrv-menu-item.list-menu-item {
}

    .hrv-menu-item.list-menu-item .hrv-menu-item-link {
        padding-left: 60px;
    }

    .hrv-menu-item.list-menu-item .hrv-menu-item-link.active:hover {
       
    }

    .hrv-menu-item.list-menu-item .hrv-menu-item-link.active {
        background: unset;
        color: hsl(218, 100%, 58%);
    }

    .hrv-menu-container {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 512;
        height: 100%;
        background: #fff;
        width: 60px;
        overflow: hidden;
        transition: width .1s ease-in-out;
    }

    .hrv-menu-logo {
        padding: 16px 22px 40px;
    }

    
.new-header__wrapper {
    height: 100%;
    width: 100%;
}

.new-header__wrapper.no-breadcrumb .new-header-breadcrumb {
    min-height: unset;
    margin: 0;
}

.new-header__wrapper.no-breadcrumb .new-header-title {
    margin: 0;
}

.new-header-breadcrumb {
    min-height: 16px;
    overflow: hidden;
    margin-top: 16px;
}

.new-header-breadcrumb .hrv-breadcrumb {
    align-items: center;
}

.new-header-breadcrumb .hrv-breadcrumb .hrv-breadcrumb-item {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #9e9e9e;
    margin: 0;
    padding: 0 8px;
}

.new-header-breadcrumb .hrv-breadcrumb .hrv-breadcrumb-item:not(:last-child)::after {
    display: none;
}

.new-header-breadcrumb .hrv-breadcrumb .hrv-breadcrumb-item:first-child {
    padding-left: 0;
}

.new-header-breadcrumb .hrv-breadcrumb .hrv-breadcrumb-item:last-child {
    color: #616161;
}

.new-header-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-top: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.hrv-header-actions {
    flex: 0 0 auto;
    max-width: 100%;
}

.hrv-header {
    position: relative;
    background: #fff;
    padding: 0 40px;
    height: 64px;
    line-height: unset;
    align-items: center !important;
}

li {
    list-style-type: none;
}

ul {
    padding: 0;
}

.hrv-menu-item-icon svg {
    vertical-align: unset;
}
.modal .hrv-modal .modal-content {
    box-shadow: 0 5px 10px 0 rgba(33, 33, 33, 0.1);
    border: 0;
    border-radius: 4px;
}

.modal .hrv-modal .modal-content .hrv-modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    border-top: 0;
    border-bottom: 1px solid #DAE3EA;
}

.modal .hrv-modal .modal-content .hrv-modal-header .hrv-modal-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.modal .hrv-modal .modal-content .hrv-modal-header .hrv-close {
    padding: 0;
    margin: 0;
    line-height: initial;
    min-width: auto;
    cursor: pointer;
}

.modal .hrv-modal .modal-content .hrv-modal-header .hrv-close svg {
    top: 2px;
    fill: #9CA7B2;
}

.modal .hrv-modal .modal-content .hrv-modal-header .hrv-close:hover svg {
    fill: #474747;
}

.modal .hrv-modal .modal-content .hrv-modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 20px;
}

.modal .hrv-modal .modal-content .hrv-modal-body.hrv-modal-body-pd-0 {
    padding: 0;
}

.modal .hrv-modal .modal-content .hrv-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    border-top: 0;
    border-top: 1px solid #DAE3EA;
}

.modal .hrv-modal .modal-content .hrv-modal-footer>.col-auto {
    padding: 0
}

.modal .hrv-modal .modal-content .hrv-modal-footer .hrv-btn {
    margin-left: 10px;
    margin-right: 0;
}

.modal-backdrop {
    display: none;
    background-color: rgba(0, 0, 0, 0.25);
}

.modal-backdrop.show {
    display: block;
    opacity: 1;
    -webkit-animation: modalBgOpen 0.15s ease;
    animation: modalBgOpen 0.15s ease;
}

.modal.fade .hrv-modal.modal-dialog {
    -webkit-transform: none;
            transform: none;
    transition: none;
}

.modal.show .hrv-modal.modal-dialog {
    transition: all 0.5s;
    -webkit-animation: modalOpen 0.15s ease;
    animation: modalOpen 0.15s ease;
    -webkit-transform: none;
            transform: none;
}

.hrv-modal.modal-dialog {
    margin: 60px auto;
}

@media screen and (max-height: 480px) {
    .hrv-modal.modal-dialog {
        margin: 10px auto;
    }
}

@media (min-width: 992px) {
    .hrv-modal.modal-sm {
        max-width: 420px;
    }
    .hrv-modal.modal-md {
        max-width: 720px;
    }
    .hrv-modal.modal-lg {
        max-width: 960px;
    }
}

@-webkit-keyframes modalOpen {
    0% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        opacity: 0
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes modalOpen {
    0% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        opacity: 0
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@-webkit-keyframes modalBgOpen {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes modalBgOpen {
    0% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.scdp-log-item {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #F5F5F5;
    padding: 15px 30px;
}

.scdp-log-item:hover {
    box-shadow: 0 2px 10px rgba(33,33,33,.15);
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    transition: all .2s ease-in-out;
}
/* @import "~@haravan/react-components/dist/index.css";
@import "~@haravan/react-components/dist/style.css"; */


.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.pointer {
  cursor: pointer;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

